/** @jsx jsx */
import { jsx } from '@emotion/react';
import config from '../../../gatsby-config';
import { Link } from 'gatsby'
import moment from 'moment';
import { grey } from "material-colors";
import { Icon } from '@blueprintjs/core';

const HeaderSection =  (props) => {
        const { i18n, t, title, author, date, timeToRead } = props;

        return (
            <header
                id="headerSection"
                key="header"
                css={styles.mainSection}
            >
                <div className="container">
                    <div key="breadcrumbs" className="row mt20 mb10">
                        <div className="col">
                            <Link to={i18n.language === 'en' ? '/' : '/es/'} >
                                {t('mainMenuLinks.home')}
                            </Link>
                            <span className="mh10"> / </span>
                            <Link to={`/${i18n.language}/blog/`} >
                                {t('blog.title')}
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h1 className="mb5" style={{ color: '#2E5CA6' }}>{title}</h1>
                        </div>
                        <div className="col-12 fs14 mb15" style={{color: grey[600] }} >
                            <span style={{ fontWeight: 'bold' }}>{moment(date).format('LL')}</span> - {` ${t('blog.postedBy')} `} <span style={{ fontWeight: 'bold' }}>{author}</span> - <span style={{ fontWeight: 'bold' }}><Icon icon="stopwatch" iconSize={20}/> {timeToRead} min</span>
                        </div>
                    </div>
                </div>
            </header>
        )
}

const styles = {
    mainSection: {
        paddingTop: config.siteMetadata.navHeaderHeight,
        // background: 'linear-gradient(#2E5CA6, #2E5CA6)'
    }
};

export default HeaderSection;