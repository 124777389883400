import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import gatsbyConfig from '../../../gatsby-config';

// Components
import MainLayout from '../../components/main-layout';
import PostHeader from './post-header';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

const Post =  (props) => {
    const { pageContext, path } = props,
        { i18n, t } = useTranslation(),
        siteUrl = gatsbyConfig.siteMetadata.siteUrl;
        const strapiUrl = gatsbyConfig.siteMetadata.strapiUrl;

    return (
        <MainLayout
            pageContext={pageContext}
        >
            <Helmet>
                <title>{pageContext.title + '| Biuwer Analytics'}</title>
                <meta property="og:title" content={pageContext.title}/>
                <meta property="og:url" content={process.env.NODE_ENV === 'development' ? 'http://localhost:8000' + path : siteUrl + path}/>
                <meta property="og:locale" content={i18n.language === "en" ? "en_US" : "es_ES"}/>
                <meta property="og:image" content={`${strapiUrl}${pageContext.imageUrl}`}/>
                <meta property="og:description" content={pageContext.description}/>
                <meta property="og:type" content="article"/>
                <meta name="twitter:title" content={pageContext.title}/>
                <meta name="twitter:description" content={pageContext.description}/>
                <meta name="twitter:image" content={`${strapiUrl}${pageContext.imageUrl}`}/>
            </Helmet>
                <PostHeader 
                    t={t} 
                    i18n={i18n} 
                    title={pageContext.title} 
                    date={pageContext.date} 
                    author={pageContext.author} 
                    slug={pageContext.slug} 
                    timeToRead={pageContext.timeToRead}
                />             
                    <div id="blog-post" className="container">
                        <div className="row mb20">
                            <div className="col">
                                <div id="body-post" className="mb20">
                                    <ReactMarkdown 
                                        children={pageContext.content} 
                                        rehypePlugins={[rehypeRaw]} 
                                        transformImageUri={uri => uri.startsWith('http') ? uri : `${strapiUrl}${uri}`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>                
        </MainLayout>
    );
};

export default Post;